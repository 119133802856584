/**
 * @generated SignedSource<<85a9c17d04930bccafb1ddc7260647e9>>
 * @relayHash 8c979458e79f0c5f416192a504b24db4
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID app-buyer-finding/505.0.0-2025-04-09T14:59:42.267Z/updateSaveSearchMutation

import { ConcreteRequest } from 'relay-runtime';
export type FavoriteAction = "ADD" | "REMOVE" | "%future added value";
export type types = "DESIGNER" | "SEARCH" | "SEARCH_HISTORY" | "SELLER" | "USER_PREFERENCE" | "%future added value";
export type FavoritePageInput = {
  action: FavoriteAction;
  clientMutationId?: string | null;
  favoriteId?: string | null;
  name?: string | null;
  page: string;
  query?: string | null;
  type: string;
  userId: string;
};
export type updateSaveSearchMutation$variables = {
  input: FavoritePageInput;
  searchPages?: ReadonlyArray<string | null> | null;
  searchTypes?: ReadonlyArray<types | null> | null;
};
export type updateSaveSearchMutation$data = {
  readonly favoritePage: {
    readonly user: {
      readonly savedSearches: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly serviceId: string | null;
          } | null;
        } | null> | null;
      } | null;
    } | null;
  } | null;
};
export type updateSaveSearchMutation = {
  response: updateSaveSearchMutation$data;
  variables: updateSaveSearchMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "searchPages"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "searchTypes"
},
v3 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "serviceId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v8 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 1
  },
  {
    "kind": "Variable",
    "name": "pages",
    "variableName": "searchPages"
  },
  {
    "kind": "Variable",
    "name": "types",
    "variableName": "searchTypes"
  }
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "updateSaveSearchMutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "FavoritePagePayload",
        "kind": "LinkedField",
        "name": "favoritePage",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "alias": "savedSearches",
                "args": null,
                "concreteType": "favoritesConnection",
                "kind": "LinkedField",
                "name": "__updateSaveSearchMutation_savedSearches_connection",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "favoritesEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Favorite",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v7/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutations",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "updateSaveSearchMutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "FavoritePagePayload",
        "kind": "LinkedField",
        "name": "favoritePage",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "alias": "savedSearches",
                "args": (v8/*: any*/),
                "concreteType": "favoritesConnection",
                "kind": "LinkedField",
                "name": "favorites",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "favoritesEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Favorite",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          (v9/*: any*/),
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v7/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": "savedSearches",
                "args": (v8/*: any*/),
                "filters": [],
                "handle": "connection",
                "key": "updateSaveSearchMutation_savedSearches",
                "kind": "LinkedHandle",
                "name": "favorites"
              },
              (v9/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "app-buyer-finding/505.0.0-2025-04-09T14:59:42.267Z/updateSaveSearchMutation",
    "metadata": {
      "connection": [
        {
          "count": null,
          "cursor": null,
          "direction": "forward",
          "path": [
            "favoritePage",
            "user",
            "savedSearches"
          ]
        }
      ]
    },
    "name": "updateSaveSearchMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "7167910ada06703f9182f57a9e0dd2a0";

export default node;
